<template>
  <div>
    <!-- <swiper></swiper> -->
    <div class="home">
      <div class="header_top">
        <handleback class="back left"></handleback>
        <div>{{ boxDetial.boxName || "" }}</div>
        <div class="speak space_between">
          <div
            class="drawV2 center btn_click"
            :class="animate ? '' : 'active_dV2'"
            @click="
              handledraw();
              playAudio();
            "
          >
            <svg
              t="1708582734439"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3632"
            >
              <path
                d="M395.765333 586.570667h-171.733333c-22.421333 0-37.888-22.442667-29.909333-43.381334L364.768 95.274667A32 32 0 0 1 394.666667 74.666667h287.957333c22.72 0 38.208 23.018667 29.632 44.064l-99.36 243.882666h187.050667c27.509333 0 42.186667 32.426667 24.042666 53.098667l-458.602666 522.56c-22.293333 25.408-63.626667 3.392-54.976-29.28l85.354666-322.421333zM416.714667 138.666667L270.453333 522.581333h166.869334a32 32 0 0 1 30.933333 40.181334l-61.130667 230.954666 322.176-367.114666H565.312c-22.72 0-38.208-23.018667-29.632-44.064l99.36-243.882667H416.714667z"
                fill="#727477"
                p-id="3633"
              />
            </svg>
          </div>
          <!-- <audio src="" id="audio" ref="audio"></audio> -->

          <div
            class="voiceV2 center btn_click"
            :class="voice ? '' : 'active_dV2'"
            @click="
              handlevoice();
              playAudio();
            "
          >
            <svg
              t="1708582870392"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3952"
            >
              <path
                d="M703.932266 193.305017l226.648416-107.435829a43.357257 43.357257 0 0 0-37.136528-78.347957l-251.430184 119.183687a43.350032 43.350032 0 0 0-24.781768 39.173978v324.229338a199.821971 199.821971 0 0 0-114.285134-35.792676c-110.824356 0-200.992422 90.160841-200.992422 200.992422 0 110.824356 90.160841 200.985197 200.992422 200.985197 110.824356 0 200.985197-90.160841 200.985198-200.985197V193.305017z m-200.992423 576.295322c-63.023721 0-114.292359-51.268638-114.292358-114.285134s51.268638-114.285134 114.292358-114.285134S617.232202 592.291484 617.232202 655.30798s-51.268638 114.292359-114.292359 114.292359zM319.590884 479.480251c16.473012-7.138305 24.037593-26.284569 16.892062-42.757581s-26.284569-24.044818-42.757581-16.892063c-44.657758 19.363014-79.106583 54.95339-96.988471 100.232499-17.889113 45.279108-17.072688 94.806519 2.297552 139.464277a32.526974 32.526974 0 0 0 42.764806 16.899287 32.512524 32.512524 0 0 0 16.892062-42.764806 116.467085 116.467085 0 0 1-1.473901-89.705666 116.365935 116.365935 0 0 1 62.373471-64.475947zM214.192507 374.544274a32.512524 32.512524 0 0 0-25.872744-59.656868C50.416087 374.703224-13.127835 535.560742 46.673534 673.471643a32.526974 32.526974 0 0 0 42.764807 16.899288 32.512524 32.512524 0 0 0 16.892062-42.764807c-45.539208-105.015452 2.846652-227.508192 107.862104-273.06185zM832.46511 663.739561a32.512524 32.512524 0 0 0-61.441445 21.313766c21.183716 61.065745-11.263783 127.976519-72.322303 149.167459a32.512524 32.512524 0 1 0 21.313765 61.426995c94.929345-32.938799 145.374331-136.97165 112.449983-231.90822zM981.032894 670.357666a32.526974 32.526974 0 0 0-61.441445 21.313766c18.178113 52.381288 14.861836 108.714655-9.334707 158.610541s-66.368899 87.386439-118.757412 105.550102a32.512524 32.512524 0 1 0 21.313765 61.441445c68.7965-23.864192 124.183391-73.095379 155.95174-138.633401 31.768348-65.516348 36.125026-139.485952 12.268059-208.282453z"
                fill="#727477"
                p-id="3953"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="box_top">
        <div class="box_list">
          <div class="box_list_i">
            <boxicon
              class="box_list_i_i animate__animated animate__flipInX"
              v-if="data.num <= 0"
            ></boxicon>
            <div class="cartoon animate__animated animate__flipInX" v-else>
              <!-- <img src="@/assets/moba.png" alt width="100%" height="100%" /> -->
            </div>
          </div>
          <div class="box_list_i">
            <boxicon
              class="box_list_i_i animate__animated animate__flipInX"
              v-if="data.num <= 1"
            ></boxicon>
            <div class="cartoon animate__animated animate__flipInX" v-else>
              <!-- <img src="@/assets/moba.png" alt width="100%" height="100%" /> -->
            </div>
          </div>
          <div class="box_list_i">
            <boxicon
              class="box_list_i_i animate__animated animate__flipInX"
              v-if="data.num <= 2"
            ></boxicon>
            <div class="cartoon animate__animated animate__flipInX" v-else>
              <!-- <img src="@/assets/moba.png" alt width="100%" height="100%" /> -->
            </div>
          </div>
          <div class="box_list_i">
            <boxicon
              class="box_list_i_i animate__animated animate__flipInX"
              v-if="data.num <= 3"
            ></boxicon>
            <div class="cartoon animate__animated animate__flipInX" v-else>
              <!-- <img src="@/assets/moba.png" alt width="100%" height="100%" /> -->
            </div>
          </div>
          <div class="box_list_i">
            <boxicon
              class="box_list_i_i animate__animated animate__flipInX"
              v-if="data.num <= 4"
            ></boxicon>
            <div class="cartoon animate__animated animate__flipInX" v-else>
              <!-- <img src="@/assets/moba.png" alt width="100%" height="100%" /> -->
            </div>
          </div>
        </div>
        <!-- <div class="box_switch">
          <div>
            音乐
            <el-switch v-model="voice" class="yy"></el-switch>
          </div>
          <div>
            动画
            <el-switch v-model="animate" class="dh"></el-switch>
          </div>
        </div>-->
        <div class="box_number">
          <div
            class="btn_click btn"
            :class="data.num == 1 ? 'active_n' : ''"
            @click="
              handleChange(1);
              playAudio();
            "
          >
            x1
          </div>
          <div
            class="btn_click btn"
            :class="data.num == 2 ? 'active_n' : ''"
            @click="
              handleChange(2);
              playAudio();
            "
          >
            x2
          </div>
          <div
            class="btn_click btn"
            :class="data.num == 3 ? 'active_n' : ''"
            @click="
              handleChange(3);
              playAudio();
            "
          >
            x3
          </div>
          <div
            class="btn_click btn"
            :class="data.num == 4 ? 'active_n' : ''"
            @click="
              handleChange(4);
              playAudio();
            "
          >
            x4
          </div>
          <div
            class="btn_click btn"
            :class="data.num == 5 ? 'active_n' : ''"
            @click="
              handleChange(5);
              playAudio();
            "
          >
            x5
          </div>
          <div ref="slider" class="slider" :style="{ left: left + 'px' }"></div>
        </div>
        <div class="box_const">
          <money class="money"></money>
          {{ total }}
        </div>
        <div
          class="btn_click box_open"
          @click="
            handleOpen();
            playAudio();
          "
        >
          <div>立即开启</div>
        </div>
      </div>
      <!-- <div class="cartoon_bg" v-if="isopen"> -->
      <div :class="isopen ? 'cartoon_bg' : 'cartoon_bg close'">
        <!-- <div class="cartoon_colum"> -->
        <div :class="isopen ? 'cartoon_colum active' : 'cartoon_colum'">
          <div class="cartoon_list">
            <div
              v-if="data.num >= 1"
              :class="isanimate == true ? 'cartoon_list_animate' : ''"
            >
              <div
                class="cartoon_list_w"
                v-for="(item, index) in weaponList1"
                :key="index"
                :style="{ 'background-image': `url(${item.levelImg})` }"
              >
                <div style class="cartoon_list_w_d">
                  <img
                    class="cartoon_d"
                    :src="item.imageUrl|fullPath"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="data.num >= 2"
              :class="isanimate == true ? 'cartoon_list_animate1' : ''"
            >
              <div
                class="cartoon_list_w"
                v-for="(item, index) in weaponList2"
                :key="index"
                :style="{ 'background-image': `url(${item.levelImg})` }"
              >
                <div class="cartoon_list_w_d">
                  <img
                    class="cartoon_d"
                    :src="item.imageUrl|fullPath"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="data.num >= 3"
              :class="isanimate == true ? 'cartoon_list_animate2' : ''"
            >
              <div
                class="cartoon_list_w"
                v-for="(item, index) in weaponList3"
                :key="index"
                :style="{ 'background-image': `url(${item.levelImg})` }"
              >
                <div class="cartoon_list_w_d">
                  <img
                    class="cartoon_d"
                    :src="item.imageUrl|fullPath"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="data.num >= 4"
              :class="isanimate == true ? 'cartoon_list_animate3' : ''"
            >
              <div
                class="cartoon_list_w"
                v-for="(item, index) in weaponList4"
                :key="index"
                :style="{ 'background-image': `url(${item.levelImg})` }"
              >
                <div class="cartoon_list_w_d">
                  <img
                    class="cartoon_d"
                    :src="item.imageUrl|fullPath"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="data.num >= 5"
              :class="isanimate == true ? 'cartoon_list_animate4' : ''"
            >
              <div
                class="cartoon_list_w"
                v-for="(item, index) in weaponList5"
                :key="index"
                :style="{ 'background-image': `url(${item.levelImg})` }"
              >
                <div class="cartoon_list_w_d">
                  <img
                    class="cartoon_d"
                    :src="item.imageUrl|fullPath"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- 横行 -->
          <div class="hengxianl"></div>

          <div class="heng_xian"></div>
          <div class="hengxianr"></div>
        </div>
      </div>
      <!-- 掉落 -->
      <div class="result animate__animated animate__fadeInDown" v-if="isResult">
        <div class="lvbu"></div>
        <div class="result_title">恭喜获得</div>
        <div class="result_center" :class="data.num == 4 ? 'p20' : ''">
          <div
            v-for="(item, index) in resultList"
            :key="index"
            class="resut_gun"
            :style="{ 'background-image': `url(${item.levelImg})` }"
          >
            <div class="gun_title">
              <div>{{ item.name }}</div>
            </div>
            <div class="gun_img">
              <img :src="item.imageUrl|fullPath" alt width="100%" height="100%" />
            </div>
            <div class="gun_name textover-f" style="margin-top: 7px">
              {{ item.itemName }}
            </div>
            <div class="gun_money money-money" style="margin-top: 8px">
              <money class="money" style="width: 13px; height: 13px"></money>
              {{ item.usePrice }}
            </div>
            <div class="button">
              <div
                style="color: #3a97e4; text-align: center; width: 100%"
                @click="
                  handleD(item.id, index);
                  playAudio();
                "
              >
                分解物品
              </div>
            </div>
          </div>
        </div>
        <div class="result_money">
          <money class="money"></money>
          {{ moneyAll }}
        </div>
        <div class="result_btn">
          <div
            class="result_btn_A btn_click"
            @click="
              handleBag();
              playAudio();
            "
          >
            放入背包
          </div>
          <div
            class="result_btn_B btn_click"
            @click="
              handleDecompose();
              playAudio();
            "
          >
            全部分解
          </div>
        </div>
      </div>

      <div class="box_center">
        <div
          class="btn_click"
          :class="type == 'hydl' ? 'active_d' : ''"
          @click="
            handletype('hydl');
            playAudio();
          "
        >
          会员掉落
        </div>
        <div
          class="btn_click"
          :class="type == 'hzbh' ? 'active_d' : ''"
          @click="
            handletype('hzbh');
            playAudio();
          "
        >
          盒子包含
        </div>
      </div>
      <div class="box_bottom grade2" v-if="type == 'hzbh'">
        <div
          class="box_bottom_hzbh"
          :style="{ 'background-image': `url(${item.levelImg})` }"
          v-for="(item, index) in boxDetial.boxOrnamentsList"
          :key="index"
        >
          <div class="hzbh_bl">{{ item.oddsResult }}%</div>
          <div class="hzbh_img">
            <img :src="item.imageUrl|fullPath" width="100%" height="100%" />
          </div>
          <div class="hzbh_name textover-f">{{ item.name }}</div>
          <div class="hzbh_money">
            <money class="money"></money>
            {{ item.usePrice }}
          </div>
        </div>
      </div>
      <div class="box_bottom grade3" v-if="type == 'hydl'">
        <div
          class="box_bottom_hydl animate__animated animate__bounceIn"
          v-for="(item, index) in boxHistory"
          :key="index"
          :style="{ 'background-image': `url(${item.ornamentLevelImg})` }"
        >
          <div class="hydl_img">
            <img :src="item.imageUrl|fullPath" width="100%" height="100%" />
          </div>
          <div class="textover-f">{{ item.ornamentName }}</div>
          <div class="hydl_money">
            <money class="moneys"></money>
            {{ item.ornamentsPrice }}
          </div>
        </div>
        <div class="isOver"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import swiper from "@/components/swiperUser.vue";
import {
  getUserInfo,
  // getBoxhistory,
  getDetail,
  openBox,
  decompose,
} from "@/api/index.js";
import bsurl from "@/api/basurl.js";
export default {
  data() {
    return {
      isopen: false,
      isanimate: null,
      isResult: false,
      voice: false,
      animate: false,
      data: {
        id: this.$route.query.id,
        num: 1,
      },
      history: {
        boxId: this.$route.query.id,
        page: 1,
        size: 9,
        orderByFie: 0,
        status: [0, 2, 3, 5],
        source: [1],
      },
      weaponList1: [],
      weaponList2: [],
      weaponList4: [],
      weaponList3: [],
      weaponList5: [],
      boxDetial: [],
      boxHistory: [],
      resultList: [],
      total: 0,
      type: "hydl",
      left: 2,
      voicefile: new Audio(),
      openAudio: new Audio(),
      totalNum: 9,
    };
  },
  components: {
    // swiper,
  },
  mounted() {
    console.log("箱子id", this.$route.query.id);

    this.connectWs();
    // this.gethistory();
    this.getdetial();
    this.voicefile.src = require("../../../assets/2.mp3");
    this.openAudio.src = require("../../../assets/01.mp3");
    // this.voicefile.load();
  },
  computed: {
    // 统计金币
    moneyAll() {
      let bean = 0;
      this.resultList.forEach((item) => {
        bean += item.usePrice * 1;
        console.log("bean", item);
      });
      return bean.toFixed(2);
    },
  },
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    connectWs() {
      getUserInfo().then((res) => {
        console.log("用户", res.data.data.userId);
        let bid = this.$route.query.id;
        let uid = res.data.data.userId;
        // console.log(fid, uid);

        this.$sockets = new WebSocket(
          bsurl.BASE_API + "/ws/bindBox/" + bid + "/" + uid
        );

        this.$sockets.onopen = () => {
          //console.log("fightRoom ==> onopen");
        };

        this.$sockets.onmessage = (event) => {
          //console.log("fightRoom ==> onmessage", event);

          let msg = JSON.parse(event.data);
          console.log("盲盒ws消息", msg);
          if (msg.code == 200) {
            if (this.boxHistory.length == 0) {
              this.boxHistory.unshift(...msg.data);
              return false;
            }
            setTimeout(() => {
              if (this.boxHistory.length < 10) {
                // console.log(123);
                this.boxHistory.unshift(...msg.data);
              } else {
                this.boxHistory.unshift(...msg.data);
                console.log(
                  "末尾删除",
                  this.boxHistory.length,
                  msg.data.length,
                  msg.data.length
                );
                this.boxHistory.splice(
                  this.boxHistory.length - msg.data.length,
                  msg.data.length
                );
              }
            }, 5500);
          }
          // 判断消息类型
        };

        this.$sockets.onclose = () => {
          console.log("fightRoom ==> onclose");
          this.roomList = [];
        };
      });
      //console.log("页面跳转参数=" + JSON.stringify(this.$route.query));
    },
    // scrollEventFn(e) {
    //   // console.log(e);
    //   if (this.type == "hydl") {
    //     this.event = e;
    //     if (
    //       e.srcElement.scrollTop + e.srcElement.clientHeight >=
    //       e.srcElement.scrollHeight
    //     ) {
    //       if (this.isOver == "加载中") {
    //         this.history.page++;
    //         setTimeout(() => {
    //           // this.gethistory();
    //         }, 5);
    //       }
    //     }
    //   }
    // },
    handlevoice() {
      this.voice = !this.voice;
    },
    handledraw() {
      this.animate = !this.animate;
    },
    reset() {
      this.isanimate = null;
      this.isopen = false;
    },
    handleD(res, index) {
      this.resultList.splice(index, 1);

      decompose({ isAll: false, packSackIds: [res] }).then((res) => {
        getUserInfo().then((res) => {
          this.user = res.data.data;
          this.$store.commit("USER_INFO", res.data.data);
        });
        this.$message({
          message: res.data.data,
          type: "success",
          customClass: "log_success",
        });
      });
      if (this.resultList.length == 0) {
        this.isResult = false;
        this.reset();
      }
    },
    handleDecompose() {
      let arr = [];
      this.resultList.forEach((i) => {
        console.log(i);
        arr.push(i.id);
      });
      console.log("arr", arr);
      decompose({ isAll: false, packSackIds: arr }).then((res) => {
        this.isResult = false;
        this.$message({
          message: res.data.data,
          type: "success",
          customClass: "log_success",
        });
        getUserInfo().then((res) => {
          this.user = res.data.data;
          this.$store.commit("USER_INFO", res.data.data);
        });
        this.reset();
      });
      // this.boxHistory = [];
      // this.history.page = 1;
      // this.gethistory();
    },
    handleBag() {
      this.isResult = false;
      this.resultList = [];
      // this.boxHistory = [];
      // this.history.page = 1;
      // this.gethistory();
      this.reset();
    },
    handleOpen() {
      if (
        this.$store.state.USER_INFO.accountAmount +
          this.$store.state.USER_INFO.accountCredits <
        this.total
      ) {
        this.$message({
          message: "余额不足，请充值",
          type: "warning",
          customClass: "log_warning",
        });
        return false;
      }
      this.getweapon();

      let obj = {};
      obj.boxId = this.data.id;
      obj.num = this.data.num;
      setTimeout(() => {
        openBox(obj).then((res) => {
          if (res.data.code == 200) {
            this.resultList = res.data.data;
            // console.log('res.data.data',res.data.data);
            this.weaponList1.push(res.data.data[0]);
            this.weaponList2.push(res.data.data[1]);
            this.weaponList3.push(res.data.data[2]);
            this.weaponList4.push(res.data.data[3]);
            this.weaponList5.push(res.data.data[4]);

            if (this.animate == false) {
              setTimeout(() => {
                this.isResult = true;
                if (!this.voice) {
                  this.openAudio.play();
                }
              }, 6000);
            } else {
              this.isResult = true;
            }
            if (this.animate == false) {
              if (!this.voice) {
                this.voicefile.play().then(() => {
                  this.isopen = true;
                  setTimeout(() => {
                    this.isanimate = true;
                  }, 5);
                });
              } else {
                this.isopen = true;
                setTimeout(() => {
                  this.isanimate = true;
                }, 5);
              }
            } else {
              this.isanimate = false;
            }
            getUserInfo().then((res) => {
              this.user = res.data.data;
              this.$store.commit("USER_INFO", res.data.data);
            });
          } else if (res.data.code == 500) {
            this.isopen = false;

            this.$message({
              type: "warning",
              message: res.data.msg,
              customClass: "log_warning",
            });
          }
          // console.log("1111", res.data.data);
        });
      }, 200);
    },
    handletype(res) {
      this.type = res;
      if (res == "hydl") {
        // this.boxHistory = [];
        // this.history.page = 1;
        // this.gethistory();
      }
    },
    handleChange(res) {
      this.getweapon();
      this.data.num = res;
      var numall = parseFloat(this.boxDetial.price * res);

      this.total = Math.round(numall * 100) / 100;

      const width = this.$refs.slider.offsetWidth;
      this.left = (res - 1) * width + 2;
    },
    handleback() {
      this.$router.back(0);
    },
    arraySort(arr) {
      arr = arr.sort((a, b) => {
        return b.usePrice - a.usePrice;
      });
      return arr;
    },
    //历史掉落
    // gethistory() {
    //   getBoxhistory(this.history).then((res) => {
    //     console.log("getBoxhistory", res);
    //     // this.boxHistory = res.data.data

    //     if (res.data.data.length < this.history.size) {
    //       this.isOver = "暂无更多";
    //     }
    //     this.boxHistory.push(...res.data.data);
    //   });
    // },
    //箱子包含
    getweapon() {
      this.weaponList1 = [];
      this.weaponList2 = [];
      this.weaponList3 = [];
      this.weaponList4 = [];
      this.weaponList5 = [];
      for (let i = 0; i < 60; i++) {
        this.weaponList1.push(
          this.weaponList[Math.floor(Math.random() * this.weaponList.length)]
        );
        this.weaponList2.push(
          this.weaponList[Math.floor(Math.random() * this.weaponList.length)]
        );
        this.weaponList3.push(
          this.weaponList[Math.floor(Math.random() * this.weaponList.length)]
        );
        this.weaponList4.push(
          this.weaponList[Math.floor(Math.random() * this.weaponList.length)]
        );
        this.weaponList5.push(
          this.weaponList[Math.floor(Math.random() * this.weaponList.length)]
        );
      }
    },
    //生成武器列表
    getdetial() {
      getDetail(this.data.id).then((res) => {
        this.boxDetial = res.data.data;
        this.total = this.boxDetial.price;
        this.weaponList = this.arraySort(res.data.data.boxOrnamentsList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 550px) {
  .cartoon_bg {
    width: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    opacity: 1;
    transform: translateX(0);
    // position: relative;
    // z-index: -1;
  }
}
.button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}
.close {
  opacity: 0 !important;
  transform: translateX(1000px) !important;
}

.op0 {
  opacity: 0;
}
.p20 {
  padding: 0 20px;
}
.cartoon_bg {
  width: 525px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 20;
  margin-left: -262.5px;
  .active {
    animation: hengxian 0.5s linear;
  }
  .cartoon_colum {
    width: 95%;
    margin: 0 auto;
    height: 112px;
    background-color: #101115;
    border-radius: 14px;
    margin-top: 62%;
    overflow: hidden;
    padding: 5px;
    position: relative;
    // animation: hengxian 0.5s linear;
    @media (max-width: 550px) {
      width: 355px;
      height: 80px;
      // background: #101115;
    }
  }
  @keyframes hengxian {
    0% {
      width: 0%;
    }
    52% {
      width: 50%;
    }
    100% {
      width: 95%;
    }
  }
  .heng_xian {
    position: absolute;
    z-index: 9999;
    top: 50%;
    margin-top: -1.5px;
    width: 100%;
    height: 3px;
    background-color: #3a97e4;
    left: 0;
    opacity: 0.87;
    margin: 0 auto;
  }

  .heng_xian:before {
    position: absolute;
    top: -50%;
    margin-top: -6px;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    border-width: 9px;
    border-left-color: #3a97e4;
  }
  .heng_xian:after {
    position: absolute;
    top: -50%;
    margin-top: -6px;
    right: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    border-width: 9px;
    border-right-color: #3a97e4;
  }
  .cartoon_list {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .cartoon_list_animate {
    transition: all 5.5s cubic-bezier(0, 0.2, 0, 1) 0s;
    transform: translateY(-5932px);
    @media (max-width: 550px) {
      transform: translateY(-4259px);
    }
  }
  .cartoon_list_animate1 {
    transition: all 5.5s cubic-bezier(0, 0.3, 0, 1) 0s;
    transform: translateY(-5932px);
    @media (max-width: 550px) {
      transform: translateY(-4259px);
    }
  }
  .cartoon_list_animate2 {
    transition: all 5.5s cubic-bezier(0, 0.2, 0, 1) 0s;
    transform: translateY(-5932px);
    @media (max-width: 550px) {
      transform: translateY(-4259px);
    }
  }
  .cartoon_list_animate3 {
    transition: all 5.5s cubic-bezier(0, 0.3, 0, 1) 0s;
    transform: translateY(-5932px);
    @media (max-width: 550px) {
      transform: translateY(-4259px);
    }
  }
  .cartoon_list_animate4 {
    transition: all 5.5s cubic-bezier(0, 0.2, 0, 1) 0s;
    transform: translateY(-5932px);
    @media (max-width: 550px) {
      transform: translateY(-4259px);
    }
  }
  .cartoon_list_w {
    background-size: 100% 100%;
    width: 92px;
    height: 98px;
    margin: 1px;
    @media (max-width: 550px) {
      width: 66px;
      height: 70px;
    }
  }
  .cartoon_list_w_d {
    width: 92px;
    height: 98px;
    @media (max-width: 550px) {
      width: 66px;
      height: 70px;
    }
  }
}
.result_bac {
  // position: absolute;
  // left: 0;
  // top: 0;
}
.result {
  margin: 0 auto;
  width: 476px;
  padding: 32px 0;
  background: #101115;
  border-radius: 23px 23px 23px 23px;
  opacity: 1;
  position: fixed;
  top: 16%;
  left: 50%;
  margin-left: -238px;
  z-index: 31;
  @media (max-width: 550px) {
    width: 340px;
    margin-left: -170px;
    padding: 25px 0;
  }
  .lvbu {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 23px 23px 23px 23px;
    height: 278px;
    background: linear-gradient(
      -218deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 38%,
      rgba(248, 125, 81, 0) 100%
    );
  }
  .result_title {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: #ffff;
    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
  .result_center {
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .resut_gun {
      width: 151px;
      height: 161px;
      margin: 4px 0 0 0;
      border-radius: 13px 13px 13px 13px;
      background: rgba(50, 52, 54, 0.25) 66%;
      background-size: 100% 100%;
      opacity: 1;
      position: relative;
      padding: 7px;
      margin: 2px;
      @media (max-width: 550px) {
        width: 108px;
        height: 115px;
        padding: 4px;
      }
      .gun_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        width: 90%;
        margin: 0 auto;
        font-weight: bold;
        @media (max-width: 550px) {
          font-size: 8px;
        }
      }
      .gun_img {
        width: 68%;
        margin: 0 auto;
      }
      .gun_name {
        font-size: 15px;
        @media (max-width: 550px) {
          font-size: 9px;
        }
      }
      .gun_money {
        font-size: 15px;
        @media (max-width: 550px) {
          font-size: 9px;
        }
      }
    }
  }
  .result_money {
    width: 90%;
    height: 56px;
    background: #323436;
    border-radius: 35px 35px 35px 35px;
    opacity: 1;
    margin: 0 auto;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #25f484;
    @media (max-width: 550px) {
      height: 40px;
    }
  }
  .result_btn {
    width: 100%;
    margin: 14px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 21px;
    .result_btn_A {
      width: 196px;
      height: 56px;
      background: linear-gradient(
        360deg,
        rgba(114, 116, 119, 0.5) 0%,
        rgba(114, 116, 119, 0.25) 100%
      );
      opacity: 1;
      border: 2px solid #727477;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 550px) {
        width: 140px;
        height: 30px;
        font-size: 15px;
      }
    }
    .result_btn_B {
      width: 196px;
      height: 56px;
      background: linear-gradient(
        360deg,
        rgba(37, 244, 132, 0.5) 0%,
        rgba(37, 244, 132, 0.25) 100%
      );
      opacity: 1;
      border: 2px solid #25f484;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 550px) {
        width: 140px;
        height: 30px;
        font-size: 15px;
      }
    }
  }
}
.box_bottom {
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  width: 100%;
  // display: flex;
  height: 420px;
  overflow-y: auto;
  // overflow: hidden;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .box_bottom_hzbh {
    position: relative;
    margin-top: 4px;
    background-size: 100% 100%;
    width: 98%;
    height: 262px;
    background-color: #1f2023;
    border-radius: 14px 14px 14px 14px;
    transition: all 0.2s ease-in-out;

    opacity: 1;
    margin: 1%;
    &:hover {
      .hzbh_img {
        transition: all 0.2s ease-in-out;
        transform: translateY(-10px);
      }
    }
    @media (max-width: 550px) {
      height: 187px;
    }

    .hzbh_img {
      transition: all 0.2s ease-in-out;

      // height: 168px;
      width: 168px;
      margin: 0 auto;
      margin-top: 30px;
      @media (max-width: 550px) {
        margin-top: 28px;

        width: 120px;
        // height: 120px;
      }
    }
    .hzbh_name {
      font-size: 19px;
      @media (max-width: 550px) {
        font-size: 13px;
      }
    }
    .hzbh_bl {
      position: absolute;
      right: 7px;
      top: 14px;
      font-weight: bold;
      @media (max-width: 550px) {
        font-size: 13px;
      }
    }
    .hzbh_money {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #25f484;
      margin-top: 12px;
      font-size: 21px;
      @media (max-width: 550px) {
        font-size: 15px;
      }
    }
  }
  .box_bottom_hydl {
    position: relative;
    margin-top: 4px;
    background-size: 100% 100%;
    width: 100%;
    height: 175px;
    background-color: #1f2023;
    border-radius: 14px 14px 14px 14px;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    font-size: 13px;

    &:hover {
      .hydl_img {
        transition: all 0.2s ease-in-out;
        transform: translateY(-10px);
      }
      // background-color: red;
    }

    @media (max-width: 550px) {
      height: 125px;
      font-size: 9px;
    }
    .hydl_img {
      width: 80%;
      transition: all 0.2s ease-in-out;
      img {
        width: 128px;
        height: 96px;
        @media (max-width: 550px) {
          width: 80px;
          height: 80px;
        }
      }
      // height: 112px;
      margin: 0 auto;
      margin-top: 5%;
      @media (max-width: 550px) {
        width: 80px;
        height: 80px;
      }
    }
    .hydl_money {
      margin-top: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      color: #25f484;
      // font-size: 14px;
      .moneys {
        margin-right: 2px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
.box_center {
  width: 203px;
  height: 42px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #727477;
  font-size: 14px;
  margin-bottom: 10px;
  @media (max-width: 550px) {
    width: 145px;
    height: 30px;
    margin-top: 25px;
    font-size: 10px;
  }

  div {
    width: 49%;
    height: 80%;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active_d {
    background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
    color: #fff;
  }
}

.box_open {
  margin: 0 auto;
  margin-top: 14px;
  width: 90%;
  height: 56px;
  background: linear-gradient(
    360deg,
    rgba(37, 244, 132, 0.2) 0%,
    rgba(37, 244, 132, 0.1) 100%
  );
  border: 1px solid #25f484;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  @media (max-width: 550px) {
    height: 40px;
    font-size: 18px;
  }
}
.box_const {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0 auto;
  color: #25f484;
  margin-top: 14px;
}
.box_number {
  width: 427px;
  height: 42px;
  background: #323436;
  border-radius: 70px 70px 70px 70px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #727477;
  transition: all 0.2s ease-in-out;

  font-weight: bold;
  position: relative;
  @media (max-width: 550px) {
    margin-top: 10px;
    width: 305px;
    height: 30px;
    font-size: 13px;
  }
  .btn {
    width: 84px;
    height: 35px;
    opacity: 1;
    color: #727477;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  .active_n {
    color: #fff;
    transition: all 0.2s ease-in-out;
  }
  .slider {
    position: absolute;
    width: 84px;
    height: 35px;
    transition: left 0.2s ease-in-out;
    border-radius: 70px 70px 70px 70px;
    transition: all 0.2s ease-in-out;

    background: linear-gradient(-270deg, #25f484 0%, #3a97e4 100%);
    @media (max-width: 550px) {
      width: 60px;
      height: 25px;
      border-radius: 50px 50px 50px 50px;
    }
  }
}
.box_list {
  width: 100%;
  height: 112px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 550px) {
    height: 80px;
  }
}
.box_list_i {
  width: 80px;
  height: 85px;
  // background-image: url("../../../assets/moba.png");
  // background-size: 100% 100%;
  // border-radius: 7px 7px 7px 7px;
  // background-position: center;
  // border: 1px solid #3a97e4;
  @media (max-width: 550px) {
    width: 57px;
    height: 61px;
  }
}
.cartoon {
  width: 80px;
  height: 85px;
  background-image: url("../../../assets/moba.png");
  background-size: 123% 123%;
  border-radius: 7px 7px 7px 7px;
  background-position: center;
  border: 1px solid #3a97e4;
  overflow: hidden;
  @media (max-width: 550px) {
    width: 57px;
    height: 61px;
  }
}
.cartoon_d {
  width: 80px;
  height: 85px;
  border-radius: 7px 7px 7px 7px;
  overflow: hidden;
  @media (max-width: 550px) {
    width: 57px;
    height: 61px;
  }
}
.box_list_i_i {
  width: 80px;
  height: 80px;
  @media (max-width: 550px) {
    width: 57px;
    height: 57px;
  }
}
.box_switch {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: right;
  display: flex;
  align-items: center;
  div {
    color: #727477;
    margin-right: 5px;
  }
}
.box_top {
  margin-top: 32px;
  @media (max-width: 550px) {
    margin-top: 23px;
  }
}
.header_top {
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back {
    width: 90px;
    @media (max-width: 550px) {
      width: 60px;
    }
  }
  .speak {
    width: 90px;
    @media (max-width: 550px) {
      width: 60px;
    }
  }
}
.home {
  margin: 0 auto;
  width: 95%;
}
::v-deep .yy {
  .el-switch__core {
    border: 3px solid #727477;
    background: none;
  }
  .el-switch__core:after {
    width: 12px;
    height: 12px;
    background-color: #3a97e4;
  }
  &.el-switch.is-checked .el-switch__core::after {
    background-color: #727477;
    margin-left: -14px;
  }
}
::v-deep .dh {
  .el-switch__core {
    background: none;
  }
  .el-switch__core {
    border: 3px solid #727477;
    background: none;
  }
  .el-switch__core:after {
    width: 12px;
    height: 12px;
    background-color: #25f484;
  }
  &.el-switch.is-checked .el-switch__core::after {
    background-color: #727477;
    margin-left: -14px;
  }
}
.drawV2,
.voiceV2 {
  width: 35px;
  height: 35px;
  @media (max-width: 550px) {
    width: 25px;
    height: 25px;
  }
  border-radius: 7px 7px 7px 7px;
  opacity: 1;
  border: 1px solid #727477;
  .icon {
    width: 20px;
    height: 21px;
    @media (max-width: 550px) {
      width: 14px;
      height: 15px;
    }
  }
}
.active_dV2,
.active_vV2 {
  border: 1px solid #25f484;
  transition: all 0.3s ease-in-out;
  svg {
    path {
      fill: #25f484;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
